.cell-center {
  text-align: center;
}

.cell-center > * {
  margin: 0 auto;
}

.small-cell-center {
  text-align: center;
  width: 120px;
}

.cell-right {
  text-align: right;
}

.cell-right > * {
  margin: 0 0 0 auto;
}

.cell-left {
  text-align: left;
}

.small-cell-left {
  text-align: left;
}

.small-cell-left > div {
  display: flex;
  width: fit-content;
  max-width: 70px;
}

.wide-cell-left {
  text-align: left;
  width: 150px;
}

.meduim-cell-left {
  text-align: left;
  width: 350px;
}

.small-cell {
  text-align: center;
}

.small-cell > div {
  display: flex;
  width: 120px;
}
